import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div class="container">
  <p class="title">OpenVQE: Advanced Quantum Optimization SDK</p>
  <p class="description">OpenVQE is an advanced software development kit (SDK) for quantum optimization, specifically the Variational Quantum Eigensolver (VQE) approach. OpenVQE is designed to facilitate efficient research in the field of quantum optimization, ensuring reliable and reproducible results.</p>
  
  <p class="open-source">OpenVQE is open-source and developed by <a href="https://www.laplace.kr">Laplace Korea</a>.</p>
  <p class="availability">Soon available !</p>
</div>
      </header>
    </div>
  );
}

export default App;
